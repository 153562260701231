import {
    SET_QUERY_RESULTS, 
    APPEND_QUERY_RESULTS,
    SET_MEASURE_SYSTEM,
    SET_CURRENT_RECORD,
    SYSTEM_METRIC,
    SYSTEM_IMPERIAL,
    UPDATE_RECORD,
    SAVED_MEASURE_SYSTEM
} from 'lib/types';
import FabricQuery from "lib/fabricQuery";
import FabricClient from "lib/fabricClient";
import {
    applyMeasureSystemTransform
} from 'lib/utils';

const measureSystems = [
    SYSTEM_METRIC,
    SYSTEM_IMPERIAL
];

export const queryRecords = (filter = "", takeCount = 100) =>  async dispatch => {
    
    try{
        const query = new FabricQuery();
        query.FilterString = filter;
        query.TakeCount = takeCount;

        /* authenticate user */
        const {nextToken, results} = await FabricClient.measureQuery(query);
        /* set query nextToken */
        query.nextToken = nextToken;
        /* dispatch user */
        dispatch({
            type: SET_QUERY_RESULTS,
            payload: {
                measureRecords: results,
                query
            }
        });
        /* return null meaning it was successful */
        return null;
    }catch(e){  
        return e.message;
    }
}
export const queryNext = () =>  async (dispatch, getState) => {
    
    try{
        /* the query */
        let {query} = getState().dataReducer;;

        /* authenticate user */
        const {nextToken, results} = await FabricClient.measureQuery(query);
        /* set query nextToken */
        query.nextToken = nextToken;
        /* dispatch user */
        dispatch({
            type: APPEND_QUERY_RESULTS,
            payload: {
                measureRecords: results,
                query
            }
        });
        /* return null meaning it was successful */
        return null;
    }catch(e){  
        return e.message;
    }
}

export const switchMeasureSystem = (currentSystem) =>  dispatch => {
    
    let nextIndex = (measureSystems.indexOf(currentSystem) + 1) % measureSystems.length;
    let next = measureSystems[nextIndex];
    /* save the current choice to local storage */
    localStorage.setItem(SAVED_MEASURE_SYSTEM, next);
    /* dispatch user */
    dispatch({
        type: SET_MEASURE_SYSTEM,
        payload: next
    });

}

export const setCurrentRecord = (record) =>  dispatch => {
    
    /* dispatch current record */
    dispatch({
        type: SET_CURRENT_RECORD,
        payload: record
    });

}

export const updateRecord = (record, selectedRecord) =>  async (dispatch, getState) => {
    
    /* the query */
    let {measureSystem} = getState().dataReducer;

    if(measureSystem != SYSTEM_IMPERIAL){
        record = applyMeasureSystemTransform([record], SYSTEM_IMPERIAL)[0];
    }
    /* updating record in backend */
    const result = await FabricClient.measureUpdate(record);
    /* dispatch current record */
    dispatch({
        type: UPDATE_RECORD,
        payload: selectedRecord
    });
    dispatch({
        type: SET_CURRENT_RECORD,
        payload: null
    });

    return true;
}