/* external imports */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
/* material ui component */
import URLImage from "components/URLImage";
import {setCurrentRecord, updateRecord } from "actions/dataActions";
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Konva from 'konva';
import { Stage, Layer, Circle, Line } from 'react-konva';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {toggleLoading} from "actions/appActions";

const styles = (theme) => ({
	root: {
	  margin: 0,
	  padding: theme.spacing(2),
	},
	closeButton: {
	  position: 'absolute',
	  right: theme.spacing(1),
	  top: theme.spacing(1),
	  color: theme.palette.grey[500],
	},
});

const DialogContent = withStyles((theme) => ({
	root: {
	  padding: theme.spacing(2),
	},
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
	root: {
	  margin: 0,
	  padding: theme.spacing(1),
	},
  }))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
	  <MuiDialogTitle disableTypography className={classes.root} {...other}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
		  <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
		  </IconButton>
		) : null}
	  </MuiDialogTitle>
	);
  });

/* this component initial state */
const initialState = {
	dimensions: {
		height: 480,
		width: 640
	},
	height: 0,
	width: 0,
	depth: 0,
	data: {},
	sideList: [],
	selectedSide: null
};
class EditDialog extends Component {
    /* set the instance initial state as initialState clone */
	state = {...initialState}

	componentWillUpdate(nextProps, nextState){
		/* if a new selected record, then set the detection data */
		if(this.props.selectedRecord != nextProps.selectedRecord && nextProps.selectedRecord != null){
			this.setDetectionData(nextProps.selectedRecord);
		}
	}
	setDetectionData(record){
		const data = {
			front: null,
			side: null
		};
		let selectedSide = '';
		let sideList = [];
		if(record.hasFrontImage){
			let fData = JSON.parse(record.frontDetection).frontDetection;
			data.front = {};
			data.front.mode = record.frontDetectionManualEdited?'manual':'auto';
			data.front.data = data.front.mode =='manual'?fData.manual:fData.auto;
			data.front.image = record.frontImage;
			selectedSide = 'front';
			sideList.push('front');
		}
		if(record.hasSideImage){
			let sData = JSON.parse(record.sideDetection).sideDetection;
			data.side = {};
			data.side.mode = record.sideDetectionManualEdited?'manual':'auto';
			data.side.data = data.side.mode =='manual'?sData.manual:sData.auto;
			data.side.image = record.sideImage;
			selectedSide = selectedSide==''?'side':selectedSide;
			sideList.push('side');
		}
		/* update component state */
		this.setState({
			...this.state,
			selectedSide,
			sideList,
			data,
			height: record.height,
			width: record.width,
			depth: record.depth
		});
	}
	handleSave = async () => {
		const {updateRecord, selectedRecord, toggleLoading} = this.props;
		const {height, width, depth, data} = this.state;
		const nRecord = {...selectedRecord};
	
		let fData = JSON.parse(nRecord.frontDetection).frontDetection;
		let sData = JSON.parse(nRecord.sideDetection).sideDetection;
		nRecord.frontDetection = fData;
		nRecord.sideDetection = sData;
		
		if(nRecord.hasFrontImage){
			fData[data.front.mode] = data.front.data;	
			nRecord.frontDetection = fData;
		}
		if(nRecord.hasSideImage){
			sData[data.side.mode] = data.side.data;	
			nRecord.sideDetection = sData;
		}
		
		/* set metrics */
		nRecord.height = height;
		nRecord.width = width;
		nRecord.depth = depth;

		/* update selected record to push into the reducer */
		selectedRecord.frontDetection = JSON.stringify({frontDetection: fData});
		selectedRecord.sideDetection = JSON.stringify({sideDetection: sData});
		selectedRecord.height = height;
		selectedRecord.width = width;
		selectedRecord.depth = depth;
		selectedRecord.portalEdited = true;

		/* update the record */
		toggleLoading();
		let result = await updateRecord(nRecord, selectedRecord);
		toggleLoading();

	}
	handleClose = () => {
		this.props.setCurrentRecord(null);
		this.setState(initialState);
	}
	handleMouseEnter = (e) => {
		console.log("mouse enter");
		e.target.setAttrs({
		  scaleX: 1.1,
		  scaleY: 1.1
		});
		e.target.getStage().draw();
	};
	handleMouseLeave = (e) => {
		e.target.setAttrs({
		  scaleX: 1,
		  scaleY: 1
		});
		e.target.getStage().draw();
	};
	handleDragEnd = (e, k)  => {

		let {data, selectedSide} = this.state;
		data[selectedSide].data[k].x = e.target.attrs.x;
		data[selectedSide].data[k].y = e.target.attrs.y;
		
		this.setState({
			...this.state,
			data
		});
	};
	renderCornerPoints = (boxData, selectedSide) =>{
		
		let measure = boxData.data;
		let keys = ['leftTop','rightTop','rightBottom','leftBottom'];
		let points = [];
		keys.forEach((k)=>{
			let p = (()=>{	
				return <Circle
					key={k}
					x={measure[k].x}
					y={measure[k].y}
					numPoints={5}
					radius={10}
					fill="lime"
					opacity={.6}
					draggable
					onMouseEnter={(e)=>this.handleMouseEnter(e,k)}
					onMouseLeave={(e)=>this.handleMouseLeave(e,k)}
					onDragEnd={(e)=>this.handleDragEnd(e,k,selectedSide)}
				/>
			})();
			points.push(p);
		});
		return points;
	}
	renderConnectingLines = (boxData, selectedSide) =>{
		
		let measure = boxData.data;
		let keys = [['leftTop','rightTop'],['rightBottom','leftBottom'],
					['leftTop','leftBottom'],['rightBottom','rightTop']];
		let lines = [];
		keys.forEach((k)=>{
			let l = (()=>{	
				return <Line
					key={k[0]+ '-' +k[1]}
					points = {[measure[k[0]].x, measure[k[0]].y, measure[k[1]].x, measure[k[1]].y]}
					stroke = 'lime'
					opacity={.6}
					strokeWidth = {2}
				/>
			})();
			lines.push(l);
		});
		return lines;
	}
	renderButtonGroup = () =>{
		const {sideList} = this.state;
		let buttons = [];
		sideList.forEach((s)=>{
			let b = (()=>{
				return (
				<Button onClick={()=>this.setState({selectedSide: s})}>
					{s}
				</Button>);
			})();
			buttons.push(b);
		});
		return buttons;
	}
	updateBoxDimension = (event, d) =>{
		this.setState({
			[d]: parseFloat(event.target.value || '0')
		});
	}
    /**
     * Renders the template component.
     * @returns {React.Component}
     */
	render() {
		/* get the component properties and state using destructuring */
		let {selectedRecord} = this.props;
		let {data, selectedSide, dimensions, height, width, depth} = this.state;
		/* if not record is selected return */
		if(!selectedRecord || !selectedSide){
			return null;
		}
		/* get the data */
		let boxData = data[selectedSide];

		/* return JSX transpiled component */
		return (
			
		<Dialog onClose={this.handleClose} aria-labelledby="dialog-edit" open={selectedRecord!=null}  maxWidth='md'>
			 <DialogTitle id="dialog-edit-title" onClose={this.handleClose}>
				Edit Measure
			</DialogTitle>
			<DialogContent ref={el => (this.container = el)} dividers>

				<Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
					<Grid item xs={3} >
						<form  noValidate autoComplete="off">
							<ButtonGroup color="primary" aria-label="outlined primary button group">
								{this.renderButtonGroup()}
							</ButtonGroup>
							<br/><br/>
							<TextField id="height" label="Height" defaultValue={height} type="number" variant="outlined" 
								onChange={(event)=>this.updateBoxDimension(event, 'height')}
							/>
							<br/><br/>
							<TextField id="width" label="Width" defaultValue={width}  type="number" variant="outlined" 
								onChange={(event)=>this.updateBoxDimension(event, 'width')}
							/>
							<br/><br/>
							<TextField id="depth" label="Depth" defaultValue={depth} type="number" variant="outlined" 
								onChange={(event)=>this.updateBoxDimension(event, 'depth')}
							/>
						</form>
					</Grid>
					<Grid item xs={9}>
						<Stage width={dimensions.width} height={dimensions.height} onMouseEnter={(evt)=>{
							evt.target.getContainer().click()
						}}>
						<Layer>
							<URLImage src={boxData.image} />
							{this.renderConnectingLines(boxData, selectedSide)}
							{this.renderCornerPoints(boxData, selectedSide)}
						</Layer>
						</Stage>
					</Grid>
				</Grid>


			</DialogContent>
			<DialogActions>
			<Button autoFocus onClick={this.handleSave} color="primary">
				Save changes
			</Button>
			</DialogActions>
		</Dialog>
		);
	}
}

/* PropTypes for data type validation */
EditDialog.propTypes = {
}
/* map the redux state values to component properties */
const mapStateToProps = (state) => {
	return {
		selectedRecord: state.dataReducer.selectedRecord
	};
};
/* map the dispatching actions to component properties */
const mapDispatchToProps = {
	//saveRecord
	setCurrentRecord,
	updateRecord,
	toggleLoading
};
/* export the component wrapping it inn the Redux connect mechanism */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditDialog);
