import React, {Component} from 'react';
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
// import IconButton from '@material-ui/core/IconButton';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import MailIcon from '@material-ui/icons/Mail';
// import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StorageIcon from '@material-ui/icons/Storage';
import SettingsIcon from '@material-ui/icons/Settings';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import logo from 'media/logo.svg'

import {routeComponent} from '../../actions/routingActions'
import {logout, authorize} from 'actions/loginActions'
import { DATA_COMPONENT, BASIC } from '../../lib/types';
import {toggleLoading} from "actions/appActions";
import { render } from '@testing-library/react';

import UnauthorizedComponent from './unathorizedLayout'
import DataComponent from 'components/dataComponent'
import EditDialog from 'components/editDialogComponent'
import MyComponent from 'components/componentTemplate'

const drawerWidth = 240;

const useStyles = theme => ({
    root: {
    display: 'flex',
    },
    drawer: {
    [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
    },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "#f1fff0 !important",
    },
    menuButton: {

    },
    logoutButton: {
        backgroundColor: '#F19720',
        '&:hover': {
            backgroundColor: '#cc7400'
        }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        backgroundColor:"#f1fff0 !important",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        flexGrow: 1
    },
});

class MainLayout extends Component {

    state = {
        mobileOpen: false
    }

    constructor(props){
        super(props)
    }

    componentWillMount(){
        const {authorize, toggleLoading} = this.props;
        (async ()=>{
            toggleLoading();
            const msg = await authorize();
            toggleLoading();
        })();
        /* TODO: if msg is not null, show alert */
    }
    // TODO: Component will mount. Make function authorize to look for token that's already in state

    handleDrawerToggle = () => {
        this.setState(Object.assign({}, {...this.state}, {mobileOpen: !this.state.mobileOpen}))
    };

    // On click functionality for the menu items.
    onMenuItemClick(type){
        this.props.routeComponent(type);
    }

    RenderLogout = (isLoggedIn, Logout) => {
        const { classes } = this.props;
        if(isLoggedIn){
            return (<Button color="inherit" className={classes.logoutButton} onClick={Logout}>Logout</Button>)
        }
    }

    Logout = () => {
        this.props.logout()
    }

    renderBody = ()=>{
        const {CurrentComponent} = this.props;

        switch(CurrentComponent){
            case DATA_COMPONENT:
                return <DataComponent/>
            case BASIC:
                return <MyComponent />
            default:
                return <DataComponent />
        }
    }

    render() {
        
        const { classes, isLoggedIn } = this.props;
        if(!this.props.isLoggedIn){
            return <UnauthorizedComponent />;
        }

        // Contains the buttons on the left hand panel.
        const drawer = (
            <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                <ListItem button key="Data" onClick={() => this.onMenuItemClick(DATA_COMPONENT)}>
                <ListItemIcon><StorageIcon /></ListItemIcon>
                <ListItemText primary="Data" />
                </ListItem>
            </List>
            {/* <Divider />
            <List>
                <ListItem button key="Account" onClick={() => this.onMenuItemClick(BASIC)}>
                <ListItemIcon><SettingsIcon/></ListItemIcon>
                <ListItemText primary="Account" />
                </ListItem>
            </List> */}
            </div>
        );

        return (
            <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                {/* <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton> */}
                <Typography variant="h6" noWrap className={classes.title}>
                    <img src={logo} height="55px" />
                </Typography>
                    {this.RenderLogout(isLoggedIn, this.Logout)}
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                <Drawer
                    container={this.state.container}
                    variant="temporary"
                    anchor={'left'}
                    open={this.state.mobileOpen}
                    onClose={this.handleDrawerToggle}
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                    <Box textAlign="left">
                        {this.renderBody()}
                    </Box>
            </main>
            <EditDialog/>
            </div>
        );
    }
    
}

MainLayout.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

const mapStateToProps = state => {
    return {
        CurrentComponent: state.routingReducer.currentComponent,
        isLoggedIn: (state.loginReducer.user != null)
    };
}

const mapDispatchToProps = {
    routeComponent,
    logout,
    authorize,
    toggleLoading
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(
    withStyles(useStyles)(MainLayout)
)