import {
    LOGIN_AUTHENTICATED,
    LOGIN_CHANGE_EMAIL,
    LOGIN_CHANGE_PASSWORD,
    LOGIN_USER_LOGOUT
} from 'lib/types'
import FabricClient from "lib/fabricClient";
import SessionManager from 'lib/sessionManager'

export const login =  (email, password) =>  async dispatch => {
    
    try{
        /* authenticate user */
        const user = await FabricClient.userAuthenticate(
            email,
            password
        );
        
        /* dispatch user */
        dispatch({
            type: LOGIN_AUTHENTICATED,
            payload: user
        });
        /* return null meaning it was successful */
        return null;
    }catch(e){       
        return e.message;
    }
}

export const authorize =  () =>  async dispatch => {
    
    try{
        /* authenticate user */
        const user = await FabricClient.userAuthorize();
        /* dispatch user */
        dispatch({
            type: LOGIN_AUTHENTICATED,
            payload: user
        });
        /* return null meaning it was successful */
        return null;
    }catch(e){  
        if(SessionManager.hasToken()){
            return "Session has expired, please re-authenticate."
        }else{
            return null;
        }        
    }
}


export const editEmail = email =>  dispatch => {   
    /* dispatch email change */
    dispatch({
        type: LOGIN_CHANGE_EMAIL,
        payload: email
    });
}

export const editPassword =  password =>  dispatch => {   
    /* dispatch email change */
    dispatch({
        type: LOGIN_CHANGE_PASSWORD,
        payload: password
    });
}

export const logout = () => dispatch => {
    SessionManager.clearSession()
    dispatch({
        type: LOGIN_USER_LOGOUT
    })
}