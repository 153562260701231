import {CONF_PASS_COMPARE, CONF_PASS_CHANGE_PASS, CONF_PASS_CHANGE_CONF_PASS} from 'lib/types'

export const changePassword = pass => dispatch => {
    dispatch({
        type: CONF_PASS_CHANGE_PASS,
        payload: {
            pass
        }
    })
}

export const changeConfirmPassword = confPass => dispatch => {
    dispatch({
        type: CONF_PASS_CHANGE_CONF_PASS,
        payload: {
            confPass
        }
    })
}