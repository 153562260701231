export default new Set([
    "depth",
    "height",
    "width",
    "weight",
    "itemCount",
    "skuNumber",
    "unitOfMeasurement",
    "locationAddress",
    //"depthMeasurementType",
    "deviceId",
    "measureDate",
    "portalEdited",
    //"frontDetection",
    //"frontDetectionManualEdited",
    "frontImage",
    //"heightMeasurementType",
    //"images",
    "sideImage",
    "skuImage",
    "nonSquareImage",
    //"RowKey",
    //"sideDetection",
    //"sideDetectionManualEdited",
    //"stringMeasureDate",
    //"widthMeasurementType"
])