const _ = require('lodash');

export default class User {
    /* properties */
    name = "";
	lastName = "";
	company = "";
	phoneNumber  = "";
	email  = "";
    password = "";
    /**
     * Validate user information.
     */
    isValid() {
        /* validate it is not empty */
        for(var prop in this){
            if(this[prop] == "") throw new Error(`User property '${prop}' is required.`);
        }
    }
    static fromObject(data){
        return _.assign(new User(), data);
     }
};
