/* external imports */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
/* material ui component */
import Button from '@material-ui/core/Button';
/* local imports */
import { myAction } from "actions/actionsTemplate";
/* this component initial state */
const initialState = {
	stateMessage: "hello world",
	counter: 0
};
class MyComponent extends Component {
    /* set the instance initial state as initialState clone */
    state = {...initialState}
	/**
	 * Handles local state button onClick event.
	 */
	handleStateClick = () => {
		/* the increase the counter */
		let counter = this.state.counter + 1;
		let stateMessage = `Next counter is ${counter}`;
		/* increment the counter */
		this.setState({
			counter,
			stateMessage
		});
	};
	/**
	 * Handles the redux action trigger.
	 */
	handleReduxClick = () => {
		this.props.myAction(new Date());
	};
    /**
     * Renders the template component.
     * @returns {React.Component}
     */
	render() {
		/* get the component properties and state using destructuring */
		let { stateMessage } = this.state;
		let { propMessage, myDate } = this.props;
		/* return JSX transpiled component */
		return (
			<div style={styles.div}>
				<h1>Account</h1>
				<Button  variant="outlined" color="primary" onClick={this.handleStateClick}>
					{stateMessage}, {propMessage}
				</Button>
				<br/>
				<br/>
				<Button variant="outlined" color="secondary" onClick={this.handleReduxClick}> 
					{myDate.getTime()} 
				</Button>
			</div>
		);
	}
}
/* define the component CSS styles */
const styles = {
	div: {
		fontSize: 48,
		color: "red",
		height: '100%',
		paddingTop: '10%',
		textAlign: 'center',
		background: "white"
	}
};
/* PropTypes for data type validation */
MyComponent.propTypes = {
	myDate: PropTypes.instanceOf(Date),
	propMessage: PropTypes.string
}
/* map the redux state values to component properties */
const mapStateToProps = (state, ownProps) => {
	return {
		myDate: state.myReducer.myDate,
		propMessage: ownProps.ownMessage
	};
};
/* map the dispatching actions to component properties */
const mapDispatchToProps = {
	myAction
};
/* export the component wrapping it inn the Redux connect mechanism */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MyComponent);
