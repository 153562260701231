import {TOGGLE_BLOCK_UI} from 'lib/types'

const initialState = {
    blocking: false
}

export default (state = initialState, action) => {
    switch(action.type) {
        case TOGGLE_BLOCK_UI:
            return {
                ...state,
                blocking: !state.blocking
            }
        default:
            return {...state}
    }
}