/* external imports */
import React, { Component } from "react";
import { connect } from "react-redux";
/* local imports */
import { queryRecords, setCurrentRecord } from "actions/dataActions";
import {toggleLoading} from "actions/appActions";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import tableColumns from "lib/tableColumns";
import {downloadDateFormat} from 'lib/utils';
import CustomToolbar from "components/customTableToolbar";
import exportColumn from "components/exportColumns";
import {
  SYSTEM_IMPERIAL,
  SYSTEM_METRIC
} from 'lib/types'
/* this component initial state */

const tableOptions = {
  selectableRows: 'none',
  selectableRowsHeader: false,
  rowsPerPageOptions: [10,20,30,40,50],
  rowsPerPage: 20,
  print: false,
  textLabels: { // This is to modify the default message for an empty table.
    body: {
      noMatch: ""
    }
  },
  customSort: (data, colIndex, order) => {
    return data.sort((a, b) => {
      console.log(colIndex, order);
      return (a.data[colIndex] < b.data[colIndex] ? -1: 1 ) * (order === 'desc' ? 1 : -1);
    });
  }
};

class DataComponent extends Component {
  
  state = {
    displayData: [],
    exportData: []
  }

  constructor(props){
    super(props);
    const {measureRecords} = this.props;
    this.setState({
      displayData: measureRecords
    })
  }
  JSDateToExcelDate(inDate) {
    let returnDateTime = 25569.0 + ((inDate.getTime() - (inDate.getTimezoneOffset() * 60 * 1000)) /(1000 * 60 * 60 * 24));
    return returnDateTime.toString().substr(0,20);
  }
  filterExportDataColumns(tableState){

    let filteredData = [];
    let filteredColumns = [];
    let exportIndexes = [];
    // extract export indexes
    for(let i in tableState.columns){
      // get the record
      let record = tableState.columns[i];
      // find the selected columns
      if(exportColumn.has(record.name)){
        exportIndexes.push({
          i: parseInt(i),
          name: record.name
        });
        filteredColumns.push(record.label)
      }
    }
    // extract columns
    tableState.data.forEach((d)=>{
      let record =  d.data;
      let filtered = [];
      for(let c in exportIndexes){
        c = exportIndexes[c];
        let content = record[c.i];
        //console.log(c.i, c.name, content);
        
        /* filter unsupported values */
        if(content instanceof Date)
          content = this.JSDateToExcelDate(content);
        if(typeof content == "undefined")
          content = "N/A";

        // add to collection
        filtered.push(content);
      }
      // add to overall results
      filteredData.push(filtered);
    });
    
    return {
      data: filteredData,
      columns: filteredColumns
    };
  }
  componentWillMount(){
    const {queryRecords, toggleLoading} = this.props;
    (async ()=>{
      
      /* this is how to implement a filter for the next iteration */
      // let filter = TableQuery.int32Filter("weight",QueryComparisons.GREATER_THAN, 3);
      // let query = new FabricQuery();
      // query.FilterString = filter;
      // query.takeCount = 2;  
        toggleLoading();
        const msg = await queryRecords();
        toggleLoading();
        /* TODO: if msg is not null, show alert */
    })();
  }
    /**
     * Renders the template component.
     * @returns {React.Component}
     */
	render() {

        const {measureSystem, measureRecords, setCurrentRecord}  = this.props;
        /* set the download format for the table */
        tableOptions.downloadOptions = {
           filename: `sku-cube-measures-${downloadDateFormat(new Date())}.csv`,
           separator: ","
        };

        tableOptions.onTableChange = (action, tableState) => {
          if(action === "filterChange" || action === "resetFilters" || action === "propsUpdate"){
            /* extract data to be exported */
            let exportData = this.filterExportDataColumns(tableState);

            /* set the state */
            this.setState({
              displayData: tableState.displayData,
              exportData: exportData
            })
          }
        }

        tableOptions.customToolbar = () => {
          return (
            <CustomToolbar  data={this.state.displayData} exportData={this.state.exportData} />
          );
        }

        tableOptions.setRowProps = (row, dataIndex) => ({
          onDoubleClick: () => {
            setCurrentRecord(measureRecords[dataIndex]);
          }
        })

        tableOptions.download = false;

        const tableTheme = () => createMuiTheme({
            overrides: {
              MUIDataTable: {
                paper: {
                  padding: "10px"
                }
              },
              MUIDataTableBodyCell: {
                root: {
                  padding: "0px",
                  textAlign: "center"
                }
              }
            }
          })

    /* replace columns */
    let colsReplacements = [
     { name: "height", imp: "Height(in)", met: "Height(cm)" },
     { name: "width", imp: "Width(in)", met: "Width(cm)" },
     { name: "depth", imp: "Depth(in)", met: "Depth(cm)" },
     { name: "weight", imp: "Weight(lb)", met: "Weight(kg)" }];
    
    /* table columns */
    tableColumns.forEach((c)=>{
      /* return e */
      let e = null;
      if(c.label){
        /* check if this is the column */
        let isPresent = colsReplacements.some((item)=>{
          e = item;
          return c.name == e.name;
        });
        /* replace accordingly */
        if(isPresent){
          c.label = (measureSystem == SYSTEM_IMPERIAL)? e.imp: e.met;
        }
      }
    }); 
    
		return (
			<div style={styles.div}>
        <MuiThemeProvider theme={tableTheme()}>
            <MUIDataTable
                title={"Measures"}
                data={measureRecords}
                columns={tableColumns}
                options={{...tableOptions}}
                />
        </MuiThemeProvider>
			</div>
		);
	}
}
/* define the component CSS styles */
const styles = {
	div: {
		fontSize: 48,
		height: '100%',
		textAlign: 'center',
		background: "white"
	}
};
/* PropTypes for data type validation */
DataComponent.propTypes = {
}
/* map the redux state values to component properties */
const mapStateToProps = (state, ownProps) => {
	return {
        measureRecords: state.dataReducer.measureRecords,
        measureSystem: state.dataReducer.measureSystem
	};
};
/* map the dispatching actions to component properties */
const mapDispatchToProps = {
  queryRecords,
  toggleLoading,
  setCurrentRecord
};
/* export the component wrapping it inn the Redux connect mechanism */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DataComponent);
