import React, {Component} from "react";
import {connect} from 'react-redux'
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { withStyles } from "@material-ui/core/styles";
import { queryNext, switchMeasureSystem } from "actions/dataActions";
import {toggleLoading} from "actions/appActions";
import {
    SYSTEM_METRIC,
    SYSTEM_IMPERIAL
} from 'lib/types'
import XLSX from 'xlsx'
import {downloadDateFormat} from 'lib/utils';
const useStyles = theme => ({
  iconButton: {},
  toggleContainer: {
  },
  FetchButton: {
    backgroundColor: '#79BF72',
    '&:hover': {
        backgroundColor: '#50804b'
    }
  },
  ExportButton: {
    backgroundColor: '#5e8eff'
  }
});

class CustomToolbar extends Component {
  handleLoadNext = () => {
    const {queryNext, toggleLoading} = this.props;
    (async ()=>{
        toggleLoading();
        const msg = await queryNext();
        toggleLoading();
        /* TODO: if msg is not null, show alert */
    })();
  }
  handleSwitchMeasureSystem = (event) => {
    const {switchMeasureSystem,
        measureSystem} = this.props;
    /* switch format */
    switchMeasureSystem(measureSystem);
  }
  renderMeasureSystemSelection = () => {
    
    const {measureSystem} = this.props;
    switch(measureSystem){
        case SYSTEM_IMPERIAL:
            return "IMPERIAL"
        case SYSTEM_METRIC:
        default:
            return "METRIC"
    }
  }
  exportToExcel = () => {
    const worbook = this.prepareExportWorkbook();
    XLSX.writeFile(worbook, `sku-cube-measures-${downloadDateFormat(new Date())}.xlsx`);
  }
  exportToSlot3D = () => {
    const worbook = this.prepareExportWorkbook();
    XLSX.writeFile(worbook, `sku-cube-measures-${downloadDateFormat(new Date())}.txt`);
  }

  prepareExportWorkbook = () => {
    const {exportData} = this.props;

    const processedData = [exportData.columns, ...exportData.data]

    const sheet = "sku_cubed_measures";
    const worksheet = XLSX.utils.aoa_to_sheet(processedData);
    let worbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(worbook, worksheet, sheet);
    return worbook;
  }

  render() {
    const { classes, 
            hasMoreRecords} = this.props;
    return (
      <React.Fragment>
        <Tooltip title={"Measurement System"}>
        <Button
            onClick={this.handleSwitchMeasureSystem}
            color="default"
            variant="outlined"
        >
            {this.renderMeasureSystemSelection()}
        </Button>

        </Tooltip>
         &nbsp;
        &nbsp;
        <Button
          variant="contained"
          color="primary"
          onClick={this.exportToExcel}
          className={classes.ExportButton}
          endIcon={<CloudDownloadIcon/>}>
          Export to Excel
        </Button>
        &nbsp;
        <Button
            variant="contained"
            color="primary"
            onClick={this.exportToSlot3D}
            className={classes.ExportButton}
            endIcon={<CloudDownloadIcon/>}>
            Export to Slot 3D
        </Button>
        &nbsp;
        <Tooltip title={"Fetch More Measures"}>
            <Button
                variant="contained"
                color="primary"
                onClick={this.handleLoadNext}
                className={classes.FetchButton}
                endIcon={<ArrowDownward/>}
                disabled={!hasMoreRecords}
            >
                Fetch
            </Button>
        </Tooltip>

      </React.Fragment>
    );
  }
}

/* PropTypes for data type validation */
CustomToolbar.propTypes = {

}
/* map the redux state values to component properties */
const mapStateToProps = (state, ownProps) => {
    const {query} = state.dataReducer;
    return {    
          hasMoreRecords: query && query.nextToken,
          measureSystem: state.dataReducer.measureSystem,
          exportData: ownProps.exportData
    };
};
/* map the dispatching actions to component properties */
const mapDispatchToProps = {
    queryNext,
    switchMeasureSystem,
    toggleLoading
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(
    withStyles(useStyles)(CustomToolbar)
)