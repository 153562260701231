import React, { Component } from 'react';
import {connect} from 'react-redux'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { withStyles } from "@material-ui/core/styles";
import "../loginComponent.css";
import {routeComponent} from 'actions/routingActions'
import {LOGIN, REGISTRATION} from 'lib/types'
import LoginComponent from 'components/loginComponent'
import RegistrationComponent from 'components/registrationComponent'

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    color: 'white'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
      background: '#15CD72',
      width: '55%',
      height: '50px'
  },
  fields: {
      width: '55%'
  },
  logo: {
      textAlign: 'center'
  }
});

class UnauthorizedComponent extends Component{
    constructor(props) {
        super(props);
    }

    renderBody = () => {
        const {CurrentComponent} = this.props;
        
        switch(CurrentComponent){
            case LOGIN:
                return <LoginComponent/>
            case REGISTRATION:
                return <RegistrationComponent />
            default:
                return <LoginComponent />
        }
    }

    /* render component */
    render(){
        return this.renderBody()
    }
}

const mapStateToProps = state => {
    return {
        CurrentComponent: state.routingReducer.currentComponent
    }
}

const mapDispatchToProps = {
    routeComponent
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(UnauthorizedComponent)