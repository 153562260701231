import React, { Component } from 'react';
import {connect} from 'react-redux'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { withStyles } from "@material-ui/core/styles";
import "./loginComponent.css";
import {routeComponent} from 'actions/routingActions'
import {toggleLoading} from "actions/appActions";
import {LOGIN} from 'lib/types'
import ConfirmPassword from 'components/reusable/confirmPassword'
import {register, changeName,
    changeLastName,
    changeCompanyCode,
    changePhone,
    changeEmail,
    //changePassword
} from 'actions/registerActions'
import MainLayout from 'components/layout/mainLayout'

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    color: 'white',
    '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
      background: '#F19720',
      width: '55%',
      height: '50px'
  },
  fields: {
      width: '55%'
  },
  logo: {
      textAlign: 'center',
      color: '#A5CB47'
  },
  email:{
      width: '90% !important'
  }
});

const initialLocalState = {
	errorOpen: false,
    errorMessage: "",
    // name: '',
    // lastname: '',
    // companyCode: '',
    // phone: '',
    // email: '',
}

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class RegistrationComponent extends Component{

    state = {...initialLocalState}

    constructor(props) {
        super(props);
    }

    handleClose = (event, reason) => {	
		this.setState({
			errorOpen: false
		})
	};

    // componentWillMount() {
        
    // }

    onRegisterClick = async (event) => {
        event.preventDefault()
        const {register, userData,toggleLoading} = this.props
         /* toggle loading */
		toggleLoading();
        const errorMessage = await register(userData)
		/* disable loading */
        toggleLoading();
        
        if(errorMessage){
			this.setState({
				errorOpen: true,
				errorMessage
			})
        }
        
        return false
    }

    onBackToLoginClick = () => {
        const {routeComponent} = this.props
        routeComponent(LOGIN)
    }

    onNameChange = (event) => {
        const {changeName} = this.props;
        changeName(event.target.value);
    }

    onLastNameChange = (event) => {
        const {changeLastName} = this.props;
        changeLastName(event.target.value)
    }

    onCompanyChange = (event) => {
        const {changeCompanyCode} = this.props;
        changeCompanyCode(event.target.value)
    }

    onPhoneChange = (event) => {
        const {changePhone} = this.props;
        changePhone(event.target.value)
    }

    onEmailChange = (event) => {
        const {changeEmail} = this.props;
        changeEmail(event.target.value)
    }

    renderRegisterButton = () => {
        const {matching, classes} = this.props;

        if(matching)
            return <Button onClick={this.onRegisterClick} className={classes.button} type="submit" variant="contained" color="primary">Register</Button>
        else
            return <Button className={classes.button} disabled type="submit" variant="contained" color="primary">Register</Button>
    }

    /* render component */
    render(){
        
        console.log(this.props);
        const { classes } = this.props;       
        return (<div className={classes.root}>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={2} lg={1} className={classes.logo}>
                    <h1>Register</h1>
                </Grid>
            </Grid>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} lg={4}>
                    <Paper className={classes.paper}>
                    <form className={classes.root} autoComplete="off">

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <TextField onChange={this.onNameChange} value={this.props.userData.name} className={classes.fields} id="name" required label="Name" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField onChange={this.onLastNameChange} value={this.props.userData.lastname} className={classes.fields} id="lastname" required label="Last Name" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField onChange={this.onCompanyChange} value={this.props.userData.companyCode} className={classes.fields} id="company" required label="Company Code" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField onChange={this.onPhoneChange} value={this.props.userData.phone} className={classes.fields} id="phone" inputProps={{pattern: "([0-9]?)[.-]?([0-9]+)[.-]([0-9]+)[.-]([0-9]{4})"}} required label="555-867-5309" />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField onChange={this.onEmailChange} value={this.props.userData.email} className={classes.email} id="email" type="email" required id="user" label="Email" />
                         </Grid>
                         {/* Render the confirm password component */}
                         <ConfirmPassword />
                    </Grid>

                        
                        <br />
                        <br />
                        {this.renderRegisterButton()}
                        <br />
                        <br />
                        <Button color="primary" onClick={this.onBackToLoginClick}>Back to Login</Button>
                    </form>
                    </Paper>
                </Grid>
            </Grid>
            <br />
            <br />
            <Snackbar open={this.state.errorOpen} autoHideDuration={6000} onClose={this.handleClose}>
                <Alert onClose={this.handleClose} severity="error">
                    {this.state.errorMessage}
                </Alert>
            </Snackbar>
        </div>);
    }
}

const mapStateToProps = state => {
    return {
        userData: {
            name: state.registerReducer.name,
            lastname: state.registerReducer.lastname,
            companyCode: state.registerReducer.companyCode,
            phone: state.registerReducer.phone,
            email: state.registerReducer.email,
            password: state.confPassReducer.pass
        },
        matching: (state.confPassReducer.pass === state.confPassReducer.confPass),
        auth: state.registerReducer.user
    }
}

const mapDispatchToProps = {
    register,
    changeName,
    changeLastName,
    changeCompanyCode,
    changePhone,
    changeEmail,
    routeComponent,
    toggleLoading
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(
    withStyles(useStyles)(RegistrationComponent)
)