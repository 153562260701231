import {
    SYSTEM_IMPERIAL,
    SYSTEM_METRIC
} from 'lib/types'

const padN = (n, padding = 1)=>{
   //debugger
   let zeroes = new Int32Array(padding);
   if(n<10)
      return `${zeroes.join("")}${n}`;
   else
      return n;
}

export const dateFormat = (d)=>{
   return [padN(d.getMonth()+1),
      padN(d.getDate()),
      padN(d.getFullYear())].join('-')+' '+
              [d.getHours(),
               d.getMinutes(),
               d.getSeconds()].join(':');
}

export const downloadDateFormat = (d)=>{
   return [padN(d.getMonth()+1),
      padN(d.getDate()),
      padN(d.getFullYear())].join('-');
}

export const roundTwoDecimals = (n) => {
   return Math.round(n * 100) / 100;
}

export const ConvertInchesToCentimeters = (inches)=>
{
    return inches * 2.54;
}

export const ConvertCentimetersToInches = (cm) =>
{
    return cm / 2.54;
}

export const ConvertKilogramsToPounds = (kilos) =>
{
    return kilos * 2.205;
}

export const ConvertPoundsToKilograms = (pounds) =>
{
    return pounds/2.205;
}

export const ConvertCentimetersToMillimeters = (cm) =>
{
    return cm * 10;
}

export const ConvertMillimetersToCentimeters = (mm) =>
{
    return mm / 10;
}

export const ConvertInchesToMillimeters = (inches) =>
{
    return inches * 25.4;
}

export const  ConvertMillimetersToInches = (mm) =>
{
    return mm / 25.4;
}

/* iterate columns and apply measure system transformation */
export const applyMeasureSystemTransform = (records, system)=>{

    let dimensionFunc = ()=>{throw new Error("Dimension function not Implemented")};
    let weightFunc = ()=>{throw new Error("Weight function not Implemented")};

    if(system == SYSTEM_METRIC){
        dimensionFunc = ConvertInchesToCentimeters;
        weightFunc = ConvertPoundsToKilograms;
    }else if(system == SYSTEM_IMPERIAL){
        dimensionFunc = ConvertCentimetersToInches;
        weightFunc = ConvertKilogramsToPounds;
    }

    /* transform data to correct measure system */
    let transformed =  records.map((r)=>{
        r.depth = roundTwoDecimals(dimensionFunc(r.depth));
        r.height = roundTwoDecimals(dimensionFunc(r.height));
        r.width = roundTwoDecimals(dimensionFunc(r.width));
        r.weight = roundTwoDecimals(weightFunc(r.weight));
        return r;
    });

    return transformed;
}