import {CONF_PASS_COMPARE, CONF_PASS_CHANGE_PASS, CONF_PASS_CHANGE_CONF_PASS, REGISTER_USER} from 'lib/types'

const initialState = {
    pass: '',
    confPass: ''
}

export default (state = initialState, action) => {
    switch(action.type) {
        case CONF_PASS_CHANGE_PASS:
            const {pass} = action.payload
            return Object.assign({}, {...state}, {pass})
        case CONF_PASS_CHANGE_CONF_PASS:
            const {confPass} = action.payload
            return Object.assign({}, {...state}, {confPass})
        case REGISTER_USER:
            return {...initialState}
        default:
            return {...state}
    }
}