const _ = require('lodash');

export default class FabricQuery {
    /* properties */
    FilterString = "";
	TakeCount = 100;
	nextToken = null;
    /**
     * Validate user information.
     */
    isValid() {
        /* validate it is not empty */
        if(typeof this.FilterString !== 'string')
            throw new Error(`FabricQuery property 'FilterString' needs to be a string.`);
        if(typeof this.TakeCount !== 'number')
            throw new Error(`FabricQuery property 'TakeCount' needs to be a number.`);
        if(typeof this.nextToken !== 'object')
            throw new Error(`FabricQuery property 'nextToken' needs to be null or an object.`);     
    }
    static fromObject(data){
        return _.assign(new FabricQuery(), data);
     }
};
