import {
    SET_QUERY_RESULTS,
    APPEND_QUERY_RESULTS,
    SYSTEM_IMPERIAL,
    SYSTEM_METRIC,
    SET_MEASURE_SYSTEM,
    SET_CURRENT_RECORD,
    UPDATE_RECORD,
    SAVED_MEASURE_SYSTEM
} from 'lib/types'
import {
    applyMeasureSystemTransform
} from 'lib/utils';
import FabricQuery from "lib/fabricQuery";

const initialState = {
    measureSystem:  localStorage.getItem(SAVED_MEASURE_SYSTEM)? localStorage.getItem(SAVED_MEASURE_SYSTEM) : SYSTEM_IMPERIAL,
    measureRecords: [],
    selectedRecord: null,
    query: null
};

export default (state = initialState, action)=> {
    switch(action.type){
        case UPDATE_RECORD:
            const index = state.measureRecords.findIndex(x => x.RowKey ===action.payload.RowKey);
            const measureRecords = [...state.measureRecords];
            measureRecords[index] = action.payload;
            return {
                ...state,
                measureRecords
            }
        case SET_CURRENT_RECORD:
            return {
                ...state,
                selectedRecord:  action.payload
            }
        case SET_MEASURE_SYSTEM:          
            return {
                ...state,
                measureRecords: applyMeasureSystemTransform(state.measureRecords, action.payload),
                measureSystem: action.payload
            }
        case SET_QUERY_RESULTS:     
            var transformedRecords = [...action.payload.measureRecords];
            if(state.measureSystem != SYSTEM_IMPERIAL){
                transformedRecords = applyMeasureSystemTransform([...action.payload.measureRecords], state.measureSystem);
            }
            return {
                ...state,
                measureRecords: transformedRecords,
                query: FabricQuery.fromObject(action.payload.query)
            }
        case APPEND_QUERY_RESULTS:   
            var transformedRecords = [...action.payload.measureRecords];
            if(state.measureSystem != SYSTEM_IMPERIAL){
                transformedRecords = applyMeasureSystemTransform([...action.payload.measureRecords], state.measureSystem);
            }       
            return {
                ...state,
                measureRecords:[
                    ...state.measureRecords ,
                    ...transformedRecords
                ],

                query: FabricQuery.fromObject(action.payload.query)
            }
        default:
            return {...state}
    }
}

