import React from 'react';
import rough from 'roughjs/bundled/rough.cjs.js';
// import ImageIcon from '@material-ui/icons/Image';
// import FabricClient from "lib/fabricClient";
import ReactTooltip from "react-tooltip";

export default [
  {
    name: "skuNumber",
    label: "Sku",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "height",
    label: "Height",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "width",
    label: "Width",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "depth",
    label: "Depth",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "weight",
    label: "Weight",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "itemCount",
    label: "Count",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "locationAddress",
    label: "Location",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "heightMeasurementType",
    label: "Height Type",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "widthMeasurementType",
    label: "Width Type",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "depthMeasurementType",
    label: "Depth Type",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "frontImage",
    options: {
      display: "false",
      filter: false,
      sort: false,
      viewColumns: false
    }
  },
  {
    name: "sideImage",
    options: {
      display: "false",
      filter: false,
      sort: false,
      viewColumns: false
    }
  },
  {
    name: "skuImage",
    options: {
      display: "false",
      filter: false,
      sort: false,
      viewColumns: false
    }
  },
  {
    name: "nonSquareImage",
    options: {
      display: "false",
      filter: false,
      sort: false,
      viewColumns: false
    }
  },
  {
    name: "RowKey",
    options: {
      display: "false",
      filter: false,
      sort: false,
      viewColumns: false
    }
  },
  {
    name: "frontDetection",
    options: {
      display: "false",
      filter: false,
      sort: false,
      viewColumns: false
    }
  },
  {
    name: "sideDetection",
    options: {
      display: "false",
      filter: false,
      sort: false,
      viewColumns: false
    }
  },
  {
    name: "frontDetectionManualEdited",
    options: {
      display: "false",
      filter: false,
      sort: false,
      viewColumns: false
    }
  },
  {
    name: "sideDetectionManualEdited",
    options: {
      display: "false",
      filter: false,
      sort: false,
      viewColumns: false
    }
  },
  {
    name: "images",
    label: "Images",
    options: {
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        if(!value) {
          return ""
        }
        else {
          var front = tableMeta.rowData[10];
          var side = tableMeta.rowData[11];
          var sku = tableMeta.rowData[12];
          var nonSquare = tableMeta.rowData[13];
          var rowKey = tableMeta.rowData[14];
          var frontDetection = tableMeta.rowData[15]? JSON.parse(tableMeta.rowData[15]):{};
          var sideDetection = tableMeta.rowData[16]? JSON.parse(tableMeta.rowData[16]):{};
          var frontDetectionManual = tableMeta.rowData[17];
          var sideDetectionManual = tableMeta.rowData[18];

          var images = [];
          images.push({key: "front", data:front});
          images.push({key: "side", data:side});
          images.push({key: "sku", data:sku});
          images.push({key: "nonSquare", data:nonSquare});

          const _style = {
            padding: "5px",
            cursor: "pointer",
            fontWeight: "bold"
          };

          const _imgDivStyle ={
            display: "flex"
          }

          const drawDetectionRect = (img, detection, detectionOff, c) =>{
            /* set correct dimensions */
            c.height = img.height;
            c.width = img.width;
            /* get context and draw */
            const ctx = c.getContext("2d");
            /* if no detections just draw image and return */
            ctx.drawImage(img, 0, 0);
            /* if not detections, just return */
            if(!detection){
              return;
            }         
            /* create rough element */
            const rc = rough.canvas(c);  
            /* draw off detection first */
            if(detectionOff){
              /* get detection points */
              var {leftBottom, leftTop, rightBottom, rightTop} = detectionOff;
              /* draw the incorrect detection box */
              rc.line(leftTop.x, leftTop.y, rightTop.x, rightTop.y, {stroke: 'red', strokeWidth: 2 });
              rc.line(leftBottom.x, leftBottom.y, rightBottom.x, rightBottom.y, {stroke: 'red', strokeWidth: 2 });
              rc.line(leftTop.x, leftTop.y, leftBottom.x, leftBottom.y, {stroke: 'red', strokeWidth: 2 });
              rc.line(rightTop.x, rightTop.y, rightBottom.x, rightBottom.y, {stroke: 'red', strokeWidth: 2 });
            }   
            /* draw official detections last */
            var {leftBottom, leftTop, rightBottom, rightTop} = detection;
            /* draw the detection box */
            rc.line(leftTop.x, leftTop.y, rightTop.x, rightTop.y, {stroke: 'limegreen', strokeWidth: 2 });
            rc.line(leftBottom.x, leftBottom.y, rightBottom.x, rightBottom.y, {stroke: 'limegreen', strokeWidth: 2 });
            rc.line(leftTop.x, leftTop.y, leftBottom.x, leftBottom.y, {stroke: 'limegreen', strokeWidth: 2 });
            rc.line(rightTop.x, rightTop.y, rightBottom.x, rightBottom.y, {stroke: 'limegreen', strokeWidth: 2 });
          }

          images = images.map((image, index) => {
            if(image.data){
              let canvas = {};
              const height = 300;
              const width = 300;             
              const _id = rowKey + "_" + image.key;
              let detection = null;
              let detectionOff = null;
              /* determine detection */
              switch(image.key){
                case "front": 
                  detection = frontDetection.frontDetection[(frontDetectionManual?"manual":"auto")]; 
                  detectionOff = frontDetectionManual? frontDetection.frontDetection.auto: null;
                  break;
                case "side":
                  detection = sideDetection.sideDetection[(sideDetectionManual?"manual":"auto")];
                  detectionOff = sideDetectionManual? sideDetection.sideDetection.auto: null;
                  break;
              }          
              return (
                <div key={index} style={_style}>
                  <a data-tip data-for={_id} onMouseOver={()=>{
                    /* download image and draw */
                    let downloadingImage = new Image();
                    downloadingImage.onload = function(){
                      drawDetectionRect(downloadingImage, detection, detectionOff, canvas);  
                    };
                    downloadingImage.src = image.data;                   
                  }}>{image.key}</a>
                  <ReactTooltip 
                    id={_id} 
                    place="left" 
                    type="light" 
                    effect="solid" 
                    className="popup" 
                    border borderColor="black" 
                  >
                    <canvas ref={(c)=>{canvas = c;}} width={width} height={height}/>
                  </ReactTooltip>
                </div>
              );
            }
          });

          return (
            <div style={_imgDivStyle}>
              {images}
            </div>
          );
        }
      }
    }
  },
  {
    name: "portalEdited",
    label: "Edited",
    options: {
      customBodyRender: (value, tableMeta, updateValue) =>{
        return value?"yes":"no";
      },
      filter: true,
      sort: true
    }
  },
  {
    name: "unitOfMeasurement",
    label: "Unit",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "deviceId",
    label: "Device",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "stringMeasureDate",
    label: "Date",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "measureDate",   
    options: {
      display: false,
      customBodyRender: ()=>"",
      filter: false,
      sort: false,
      viewColumns: false
    }
  }
];
