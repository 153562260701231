/**
 * Author: Victor Santos
 * Description: Fabric Azure Client helper class file to communicate with backend.
 */
/* external imports */
import sha256 from 'crypto-js/sha256';

import User from "lib/user"; 
import FabricQuery from "lib/fabricQuery";
import {TableQuery, TableUtilities} from 'azure-storage';
/* local imports */
import SessionManager from "lib/sessionManager";
import {
    FABRIC_ENDPOINT,
    FABRIC_ACCESS_KEY
} from 'lib/environment';
import Query from './fabricQuery';
import {dateFormat} from 'lib/utils';

const {TableOperators, QueryComparisons } = TableUtilities;

/* the routes */
const ROUTES = {
    measureWrite: '/feature/writemeasure',
    measureUpdate: '/feature/updatemeasure',
    measureQuery: '/feature/querymeasure',
    userRegister: '/user/register',
    userAuthenticate: '/user/authenticate',
    userAuthorize: '/user/authorize',
    retrieveImageUrl: '/feature/retrieveimageurl'
}
/**
 * Fabric class serves a helper utility to communicate Fabric Azure backend with front end application.
 */
export default class FabricClient {

    static async measureUpdate(measure) {
        const result = await this._sendPost(ROUTES.measureUpdate, measure);
        return result;
    }
    static async measureWrite(measure) {
        /* validate measure */
        measure.isValid();
        const result = await this._sendPost(ROUTES.measureWrite, measure);
        return result;
    }
    static async measureQuery(query) {
        /* validate query */
        query.isValid();
        const data = await this._sendPost(ROUTES.measureQuery, query);
        /* parse the date */
        for(let i = 0; i <  data.results.length; i++){
            let e = data.results[i];
            e.measureDate = new Date(e.measureDate);
            e.stringMeasureDate = dateFormat(e.measureDate);
        }
        return data;    
    }

    static async retrieveImageUrl(filename) {
        const image = await this._sendPost(ROUTES.retrieveImageUrl, filename);
        return image.data;
    }
    static async userRegister(user) {
        /* validate user */
        user.isValid();
        /* register user */
        const {jwt} = await this._sendPost(ROUTES.userRegister, user);
        /* set the authentication token */
        SessionManager.setJWTToken(jwt);
        /* return provided user */
        return user;
    }
    static async userAuthenticate(email, password) {
        const {jwt, user} = await this._sendPost(ROUTES.userAuthenticate, {
            email,
            password
        });
        /* set the authentication token */
        SessionManager.setJWTToken(jwt);
        return user;
    }
    static async userAuthorize() {
        const {jwt, user} = await this._sendPost(ROUTES.userAuthorize, {});
        SessionManager.setJWTToken(jwt);
        return user;
    }
    /**
     * Send post request to the provided fabric route endpoint.
     * 
     * @param {string} route of the request.
     * @param {object} data to send to the fabric endpoint.
     */
    static async _sendPost(route, data){    
        /* build the url endpoint and headers */
        const url = `${FABRIC_ENDPOINT}${route}?code=${FABRIC_ACCESS_KEY}`;
        const signature = sha256(JSON.stringify(data)).toString();
        const authorization = `Bearer ${SessionManager.getJWTToken()}`; 
        /* build and fetch the endpoint */
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': authorization,
              'Signature': signature
            },
            body: JSON.stringify(data) 
          });

          if(response.ok){
            let {data} = await response.json();
            return data;
          }else{
            let {error} = await response.json();
            throw new Error(error);
          }
    }
};