import {
    LOGIN_CHANGE_EMAIL, 
    LOGIN_CHANGE_PASSWORD,
    LOGIN_AUTHENTICATED,
    LOGIN_USER_LOGOUT,
    REGISTER_USER
} from 'lib/types'

const initialState = {
    email: '',
    password: '',
    user: null
};

export default (state = initialState, action)=> {

    switch(action.type){
        case LOGIN_CHANGE_EMAIL:          
            return {
                ...state,
                email: action.payload
            }
        case LOGIN_CHANGE_PASSWORD:
            return {
                ...state,
                password: action.payload
            }
        case LOGIN_AUTHENTICATED:
        case REGISTER_USER:
            return {
                ...state,
                user: action.payload
            }
        case LOGIN_USER_LOGOUT:
            return {
                ...initialState
            }
        default:
            return {...state}
    }
}