import React, {Component} from 'react'
import {connect} from 'react-redux'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import {changePassword, changeConfirmPassword} from 'actions/confirmPassActions'

const useStyles = theme => ({
    root: {
      flexGrow: 1,
      color: 'white',
      '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: 200,
      }
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    button: {
        background: '#15CD72',
        width: '55%',
        height: '50px'
    },
    fields: {
        width: '55%'
    },
    logo: {
        textAlign: 'center'
    }
  });

class ConfirmPassword extends Component {

    state = {
        pass: '',
        confPass: '',
        callback: null
    }

    constructor(props) {
        super(props);
        //this.setState(Object.assign({}, {...this.state}, {callback: this.props.callback}))
    }

    onPasswordChange = (event) => {
        //console.log(event.target)

        if(event.target.id === "pass") {
            this.props.changePassword(event.target.value)
        } else if(event.target.id === "confPass") {
            this.props.changeConfirmPassword(event.target.value)
        }
        else
            return
        
        //this.props.callback()
    }

    renderTextBoxes = () => {
        const {pass, confPass, classes} = this.props;
        if(pass === confPass) {
            return (
                <React.Fragment>
                    <Grid item xs={12} sm={6}>
                    <TextField className={classes.fields} value={this.props.pass} required type="password" onChange={this.onPasswordChange} id="pass" label="Password" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField className={classes.fields} value={this.props.confPass} required type="password" onChange={this.onPasswordChange} id="confPass" label="Confirm Password" />
                    </Grid> 
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <Grid item xs={12} sm={6}>
                        <TextField className={classes.fields} value={this.props.pass} error helperText="Passwords don't match" required type="password" onChange={this.onPasswordChange} id="pass" label="Password" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField className={classes.fields} value={this.props.confPass} error helperText="Passwords don't match" required type="password" onChange={this.onPasswordChange} id="confPass" label="Confirm Password" />
                    </Grid> 
                </React.Fragment>
            )
        }
    }


    render() {
        return this.renderTextBoxes();    
    }


}

const mapStateToProps = state => {
    // console.log(state.confPassReducer)
    return {
        pass: state.confPassReducer.pass,
        confPass: state.confPassReducer.confPass
    }
}

const mapDispatchToProps = {
    changePassword,
    changeConfirmPassword
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(
    withStyles(useStyles)(ConfirmPassword)
)