
const JWT_TOKEN_KEY = "JWT_SESSION_TOKEN";

export default class SessionManager {
    
    static setJWTToken(token) {
        localStorage.setItem(JWT_TOKEN_KEY, token);
    }
    static getJWTToken(){
        return localStorage.getItem(JWT_TOKEN_KEY);
    }
    static hasToken(){
        return localStorage.getItem(JWT_TOKEN_KEY) !== null;
    }
    static clearSession(){
        return localStorage.clear();
    }

};
