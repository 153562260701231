import React , {Component} from 'react';
import {connect} from 'react-redux'
import MainLayout from "components/layout/mainLayout";
import BlockUi from 'react-block-ui';
import { Loader, Types } from 'react-loaders';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';
import './App.css';

class App extends Component {

  render(){
      return (
        <BlockUi tag="div" style={styles.blockUI} blocking={this.props.blocking} loader={<Loader active type="ball-grid-beat" color="#F19720"/>}>
          <MainLayout></MainLayout>
        </BlockUi>
      );
  }
}

const styles={
  blockUI: {
    height: '100%',
    zIndex: '2000 !important'
  }
}

const mapStateToProps = state => {
  return {
    blocking: state.appReducer.blocking
  }
}
const mapDispatchToProps = {
}
export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(App)

