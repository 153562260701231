import {
    TOGGLE_BLOCK_UI
} from 'lib/types'


export const toggleLoading =  () =>  dispatch => {
    
    /* dispatch the toggle user */
    dispatch({
        type: TOGGLE_BLOCK_UI,
    });
}