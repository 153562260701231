import {REGISTER_USER, REGISTER_CHANGE_NAME, REGISTER_CHANGE_LAST_NAME, REGISTER_CHANGE_COMPANY_CODE, REGISTER_CHANGE_PHONE,
    REGISTER_CHANGE_EMAIL, CONF_PASS_CHANGE_PASS} from 'lib/types'

const initialState = {
    name: '',
    lastname: '',
    companyCode: '',
    phone: '',
    email: '',
    password: ''
}

export default (state = initialState, action) => {
    switch(action.type){
        case REGISTER_USER:
            return {...initialState}
        case REGISTER_CHANGE_NAME:
            return Object.assign({}, {...state}, {name: action.payload.name})
        case REGISTER_CHANGE_LAST_NAME: 
            return Object.assign({}, {...state}, {lastname: action.payload.lastname})
        case REGISTER_CHANGE_COMPANY_CODE:
            return Object.assign({}, {...state}, {companyCode: action.payload.companyCode})
        case REGISTER_CHANGE_PHONE:
            return Object.assign({}, {...state}, {phone: action.payload.phone})
        case REGISTER_CHANGE_EMAIL:
            return Object.assign({}, {...state}, {email: action.payload.email})
        case CONF_PASS_CHANGE_PASS:
            return Object.assign({}, {...state}, {password: action.payload.pass})
        default:
            return {...state}
    }
}