import {DATA_COMPONENT, BASIC, LOGIN, REGISTRATION, REGISTER_USER} from '../lib/types'

const initialState = {
    currentComponent: null
}
// Routing actions
function routeComponent (state = initialState, action) {
    
    switch(action.type) {
        case DATA_COMPONENT:
            return {currentComponent:DATA_COMPONENT}
        case BASIC:
            return {currentComponent:BASIC}
        case LOGIN:
        case REGISTER_USER:
            return {currentComponent:LOGIN}
        case REGISTRATION:
            return {currentComponent:REGISTRATION}
        default:
            return {...state}
    }
}

export default routeComponent