import {REGISTER_USER, REGISTER_CHANGE_NAME, REGISTER_CHANGE_LAST_NAME, REGISTER_CHANGE_COMPANY_CODE, REGISTER_CHANGE_PHONE,
    REGISTER_CHANGE_EMAIL} from 'lib/types'
import User from '../lib/user'
import FabricClient from "lib/fabricClient"

export const register = userData => async dispatch => {
    try {
        const user = new User();
        user.name = userData.name;
        user.lastName = userData.lastname;
        user.company = userData.companyCode;
        user.phoneNumber = userData.phone;
        user.email = userData.email;
        user.password = userData.password;

        const response = await FabricClient.userRegister(user);
        //const response = {}

        dispatch({
            type: REGISTER_USER,
            payload: response
        })
    } catch (e) {
        return e.message;
    }
}

export const changeName = name => dispatch => {
    dispatch({
        type: REGISTER_CHANGE_NAME,
        payload: {
            name
        }
    })
}

export const changeLastName = lastname => dispatch => {
    dispatch({
        type: REGISTER_CHANGE_LAST_NAME,
        payload: {
            lastname
        }
    })
}

export const changeCompanyCode = companyCode => dispatch => {
    dispatch({
        type: REGISTER_CHANGE_COMPANY_CODE,
        payload: {
            companyCode
        }
    })
}

export const changePhone = phone => dispatch => {
    dispatch({
        type: REGISTER_CHANGE_PHONE,
        payload: {
            phone
        }
    })
}

export const changeEmail = email => dispatch => {
    dispatch({
        type: REGISTER_CHANGE_EMAIL,
        payload: {
            email
        }
    })
}