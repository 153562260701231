// APPLICATION
export const TOGGLE_BLOCK_UI = "TOGGLE_BLOCK_UI";

// Data Table
export const SET_QUERY_RESULTS = "SET_QUERY_RESULTS";
export const UPDATE_RECORD = "UPDATE_RECORD";
export const APPEND_QUERY_RESULTS = "APPEND_QUERY_RESULTS";

export const SET_CURRENT_RECORD = "SET_CURRENT_RECORD";
export const SET_MEASURE_SYSTEM = "SET_MEASURE_SYSTEM";
export const SAVED_MEASURE_SYSTEM = "SAVED_MEASURE_SYSTEM";
export const SYSTEM_METRIC = "SYSTEM_METRIC";
export const SYSTEM_IMPERIAL = "SYSTEM_IMPERIAL";

// Routing
export const DATA_COMPONENT = "DATA_COMPONENT"
export const BASIC = "BASIC"
export const LOGIN = "LOGIN"
export const REGISTRATION = "REGISTRATION"

// Login
export const LOGIN_AUTHENTICATED = "LOGIN_AUTHENTICATED";
export const LOGIN_CHANGE_EMAIL = "LOGIN_CHANGE_EMAIL";
export const LOGIN_CHANGE_PASSWORD = "LOGIN_CHANGE_PASSWORD";
export const LOGIN_USER_LOGOUT = "LOGIN_USER_LOGOUT";

// Confirm Password
export const CONF_PASS_CHANGE_PASS = "CONF_PASS_CHANGE_PASS"
export const CONF_PASS_CHANGE_CONF_PASS = "CONF_PASS_CHANGE_CONF_PASS"

// Register
export const REGISTER_USER = "REGISTER_USER"
export const REGISTER_CHANGE_NAME = "REGISTER_CHANGE_NAME";
export const REGISTER_CHANGE_LAST_NAME = "REGISTER_CHANGE_LAST_NAME";
export const REGISTER_CHANGE_COMPANY_CODE = "REGISTER_CHANGE_COMPANY_CODE";
export const REGISTER_CHANGE_PHONE = "REGISTER_CHANGE_PHONE";
export const REGISTER_CHANGE_EMAIL = "REGISTER_CHANGE_EMAIL";
export const REGISTER_CHANGE_PASSWORD = "REGISTER_CHANGE_PASSWORD";