import React, { Component } from 'react';
import {connect} from 'react-redux'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from "@material-ui/core/styles";
import "./loginComponent.css";
import logo from 'media/logo.svg'

import {login, editEmail, editPassword} from "actions/loginActions";
import {toggleLoading} from "actions/appActions";
import {routeComponent} from "actions/routingActions"
import {REGISTRATION} from "lib/types"

const useStyles = theme => ({
  root: {
    flexGrow: 1,
	color: 'white',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
      background: '#F19720',
      width: '55%',
      height: '50px'
  },
  fields: {
      width: '75%'
  },
  logo: {
      textAlign: 'center'
  }
});

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialLocalState = {
	errorOpen: false,
	errorMessage: ""
}

class LoginComponent extends Component{
	state = {
		...initialLocalState
	}
    constructor(props) {
        super(props);
	}
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			this.onLoginClick();
		}
	  }
	handleClose = (event, reason) => {	
		this.setState({
			errorOpen: false
		})
	};
	onLoginClick = async ()=>{
		const {login, email, password, toggleLoading} = this.props;
		/* toggle loading */
		toggleLoading();
		const errorMessage = await login(email, password);	
		/* disable loading */
		toggleLoading();

		if(errorMessage){
			this.setState({
				errorOpen: true,
				errorMessage
			})
		}
	}
	onEmailChange = (event)=>{
		const {editEmail} = this.props;
		editEmail(event.target.value);
	}
	onPasswordChange = (event)=>{
		const {editPassword} = this.props;
		editPassword(event.target.value);
	}

	onRegisterClick = () => {
		const {routeComponent} = this.props;
		routeComponent(REGISTRATION);
	}
    /* render component */
    render(){

		const { classes, email, password } = this.props; 		      
        return (<div id="loginContainer" className={classes.root}>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} lg={2} className={classes.logo}>
                    {/* <h1>SKU<sup>3</sup></h1> */}
					<img src={logo} height="55px" />
                </Grid>
            </Grid>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} lg={4}>
                    <Paper className={classes.paper}>
                    <form className={classes.root} noValidate autoComplete="off">
						<TextField 	className={classes.fields} 
									required={true}
									id="user" 
									label="Email" 
									value={email} 
									variant="outlined"
									onKeyDown={this._handleKeyDown}
									onChange={this.onEmailChange}
									/>
                        <br />
                        <br />
						<TextField 	className={classes.fields}  
									required={true}
									type="password" 
									id="pass" 
									label="Password" 
									value={password} 
									variant="outlined"
									onKeyDown={this._handleKeyDown}
									onChange={this.onPasswordChange} 
									/>
                        <br />
                        <br />
						<Button className={classes.button} 
								variant="contained" 
								color="primary"
								onClick={this.onLoginClick}
								>Log In</Button>
                        <br />
                        {/* <br /> */}
                        {/* <Button color="primary">Forgot Password?</Button> */}
						<br />
						<Button color="primary" onClick={this.onRegisterClick} >Register</Button>

						<Snackbar open={this.state.errorOpen} autoHideDuration={6000} onClose={this.handleClose}>
							<Alert onClose={this.handleClose} severity="error">
							  {this.state.errorMessage}
							</Alert>
						</Snackbar>
                    </form>
                    </Paper>
                </Grid>
            </Grid>
        </div>);
    }
}

const mapStateToProps = state => {
    return {
		email: state.loginReducer.email,
		password: state.loginReducer.password,
		//RegisterComponent: state.routingReducer.currentComponent
    }
}

const mapDispatchToProps = {
		login, 
		editEmail, 
		editPassword,
		toggleLoading,
		routeComponent,
}

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(
    withStyles(useStyles)(LoginComponent)
)